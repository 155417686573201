import React from 'react';
import styled from 'styled-components';
import moment, { Moment } from 'moment-timezone';

import useLoadAllFpiData from 'hooks/useLoadAllFpiData';
import FpiLine, { fpiLevels } from './FpiLine';

const Wrapper = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 2rem;
  text-transform: uppercase;
  gap: 1rem;

  @media (max-width: 600px) {
    margin: 1rem 1rem 1rem 1.5rem;
  }
`;

const Left = styled.div`
  flex-grow: 1;
`;

const Days = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  font-family: 'Interstate Condensed';
  font-weight: bold;
  color: ${(props) => props.theme.foreground};
  line-height: 1.1;
  text-align: center;
  font-size: 0.875rem;
  height: 2.25rem;
`;

const Right = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 0.25rem 0.75rem;
  color: ${(props) => props.theme.foreground};
  font-size: 0.75rem;
  margin-top: 2.4rem;

  & > div:nth-child(odd) {
    text-align: right;
  }

  @media (max-width: 600px) {
    display: none;
  }
`;

const Middle = ({ area }: { area: string }) => {
  const { allFpiData } = useLoadAllFpiData();
  const { fpiTimeOfDay } = useLoadAllFpiData();

  const today = moment().startOf('day');
  const days: Moment[] = [];

  for (let i = 0; i < 7; i++) {
    days.push(today.clone().add(i, 'days'));
  }

  const fpiDataForArea = allFpiData?.filter((f) => f.region === area);
  const lineData = days.map((day, idx) => {
    if (idx === 0) {
      let dayOptions = fpiDataForArea?.filter((f) => moment(f.valid_time).isSame(day, 'day'));
      if (fpiTimeOfDay === 'day') {
        let found = dayOptions?.find((day) => moment(day.valid_time).format('A') === 'AM');
        return found?.fpi;
      } else {
        let found = dayOptions?.find((day) => moment(day.valid_time).format('A') === 'PM');
        return found?.fpi;
      }
    } else {
      return fpiDataForArea?.find((f) => moment(f.valid_time).isSame(day, 'day'))?.fpi;
    }
  });

  return (
    <Wrapper>
      {allFpiData && (
        <>
          <Left>
            <Days>
              {days.map((day) => (
                <div key={day.toISOString()}>
                  {day.format('ddd')}
                  <br />
                  {day.format('M/D')}
                </div>
              ))}
            </Days>
            <FpiLine level={fpiLevels.Extreme} fpis={lineData} />
            <FpiLine level={fpiLevels.Elevated} fpis={lineData} />
            <FpiLine level={fpiLevels.Normal} fpis={lineData} />
          </Left>
          <Right>
            <div>Extreme:</div>
            <div>15 - 17</div>
            <div>Elevated:</div>
            <div>12 - 14</div>
            <div>Normal:</div>
            <div>&lt; 12</div>
          </Right>
        </>
      )}
    </Wrapper>
  );
};

export default Middle;
