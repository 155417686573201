import React from 'react';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';

import Left from './components/Left';
import Middle from './components/Middle';
import Right from './components/Right';

const Wrapper = styled.div`
  position: absolute;
  bottom: 0.5rem;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 1rem);
  max-width: 80rem;
  height: 9.375rem;
  border-radius: 1.875rem;
  overflow: hidden;
  background-color: ${(props) => props.theme.backgroundBottomNav};
  display: flex;
`;

interface Props {
  area: string;
  setOverlayArea: (area: string) => void;
}

const BottomBar = ({ area, setOverlayArea }: Props) => {
  const isSmallDevice = useMediaQuery({ query: '(max-width: 767px)' });

  if (area == null) return null;

  return (
    <>
      {isSmallDevice && <Left />}
      <Wrapper>
        {!isSmallDevice && <Left />}
        <Middle area={area} />
        <Right area={area} setOverlayArea={setOverlayArea} />
      </Wrapper>
    </>
  );
};

export default BottomBar;
