import React from 'react';
import styled from 'styled-components';
import { fpiLongLevelFromValue } from '@sdge-web-app/shared/dist/utils';
import { COLORS } from '@sdge-web-app/shared/dist/constants';

const Wrapper = styled.div``;

const DayCircle = styled.div<{ active?: boolean }>`
  width: ${({ active }) => (active ? '1.1875rem' : '0.3125rem')};
  height: ${({ active }) => (active ? '1.1875rem' : '0.3125rem')};
  margin: ${({ active }) => (active ? '0' : '0 0.4375rem')};
  border-radius: 50%;
  font-family: 'Interstate Condensed';
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;

  &:first-child {
    margin: ${({ active }) => (active ? '0' : '0 0.4375rem')};
  }
`;

const Line = styled.div`
  width: calc(100% - 1.1875rem);
  height: 0.0625rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const LineWrapper = styled.div<{ lineColor: string }>`
  position: relative;
  width: 100%;
  height: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${DayCircle} {
    position: relative;
    background: ${({ lineColor }) => lineColor};
    color: ${({ lineColor }) => (lineColor === COLORS.accessibleYellow ? 'black' : 'white')};
    z-index: 10;
  }

  ${Line} {
    background: ${({ lineColor }) => lineColor};
  }
`;

export enum fpiLevels {
  'Normal',
  'Elevated',
  'Extreme',
}

const getLineColor = (level: fpiLevels) => {
  switch (level) {
    case fpiLevels.Normal:
      return COLORS.fieldGreen;
    case fpiLevels.Elevated:
      return COLORS.accessibleYellow;
    case fpiLevels.Extreme:
      return COLORS.energyRed;
  }
};

const FpiLine = ({ level, fpis }: { level: fpiLevels; fpis: (number | null | undefined)[] }) => {
  return (
    <Wrapper>
      <LineWrapper lineColor={getLineColor(level)}>
        <Line />
        {fpis.map((fpi, i) => {
          const active = fpi != null && fpiLongLevelFromValue(fpi) === fpiLevels[level];
          return (
            <DayCircle data-cy={`day-fpi-circle${active ? '-active' : ''}`} key={i} active={active}>
              {active && fpi}
            </DayCircle>
          );
        })}
      </LineWrapper>
    </Wrapper>
  );
};

export default FpiLine;
