import styled from 'styled-components';

const Triangle = styled.div`
  position: absolute;
  top: -2rem;
  z-index: 1;
  svg {
    path {
      fill: ${(props) => props.theme.backgroundCircle};
    }
  }
`;

export const BottomTriangle = (): JSX.Element => {
  return (
    <Triangle>
      <svg
        id="Triangle"
        data-name="Triangle"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 98.94 49.71"
        height={'1rem'}>
        <path className="cls-1" d="M.75.68c.48-.4,97,0,97,0l-48.01,48.32L.75.68Z" />
      </svg>
    </Triangle>
  );
};
