import styled from 'styled-components';
import { SolidDial } from './SolidDial';
import { BottomTriangle } from './Triangle';

type PinProps = {
  widgetName: string;
  current: string | number | boolean;
  innerText?: string;
};

const Wrapper = styled.div`
  display: inline-block;
`;

const SubtextWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  filter: drop-shadow(0 0.2rem 1rem rgba(0, 0, 6, 0.15));
  font-size: var(--pin-subtext-font-size);
`;

export function SolidPin({ widgetName, current, innerText }: PinProps) {
  return (
    <Wrapper>
      <SolidDial large widgetName={widgetName} current={current} innerText={innerText} />
      <SubtextWrapper>{BottomTriangle()}</SubtextWrapper>
    </Wrapper>
  );
}
