import './App.css';
import '@sdge-web-app/shared/dist/assets/shared.css';
import 'sanitize.css';

import useFpiLoader from './hooks/useLoadFpiData';

import Map from './components/Map';
import Overlay from './components/Overlay';
import { useState, useEffect } from 'react';
import BottomBar from './components/BottomBar';
import { FpiRegion } from '@sdge-web-app/shared/dist/types';

import { useStores } from 'store';
import { observable, observe } from 'mobx';
import { observer } from 'mobx-react-lite';
import { ThemeProvider } from 'styled-components';
import { COLORS } from '@sdge-web-app/shared/dist/constants';
import { ReactComponent as Logo } from 'assets/svg/logo.svg';
import { ReactComponent as LogoDark } from 'assets/svg/logo_dark.svg';
import { ReactComponent as LinkIcon } from 'assets/svg/link.svg';
import { ReactComponent as LinkIconDark } from 'assets/svg/link_dark.svg';

const theme = {
  logo: Logo,
  background: 'white',
  backgroundBottomNav: '#f8f8f8',
  backgroundCircle: 'white',
  briefingBackground: COLORS.briefingBackground,
  borderColor: '#f1f1f1',
  hoverColor: COLORS.lightGray,
  bottomNavToggle: 'white',
  linkIcon: LinkIcon,
  foreground: 'black',
  foregroundGray: COLORS.gray,
  mapPinBackground: 'white',
  mapPinText: '#787878',
  safetyMessage: COLORS.lightGray,
  safetyMessageToggle: 'white',
  columnWrapper: '#666366',
  forecastSlideText: '#666',
  scrollbarColor: 'none',
  iconHoverColor: COLORS.lightGray,
};

const darkMode = {
  logo: LogoDark,
  background: '#1F2023',
  backgroundBottomNav: '#1F2023',
  backgroundCircle: 'black',
  briefingBackground: '#1F2023',
  borderColor: 'black',
  hoverColor: '#666366',
  bottomNavToggle: '#1F2023',
  linkIcon: LinkIconDark,
  foreground: 'white',
  foregroundGray: 'white',
  mapPinBackground: 'black',
  mapPinText: 'white',
  safetyMessage: '#1F2023',
  safetyMessageToggle: '#1F2023',
  columnWrapper: 'white',
  forecastSlideText: 'white',
  scrollbarColor: '#1F2023 black',
  iconHoverColor: ' #1F2023',
};

const App = observer(() => {
  useEffect(() => {
    const mq = window.matchMedia('(prefers-color-scheme: dark)');

    if (mq.matches) {
      mapStateStore.setDarkMode(true);
    }

    // This callback will fire if the perferred color scheme changes without a reload
    mq.addEventListener('change', (evt) => mapStateStore.setDarkMode(evt.matches));
  }, []);

  const { mapStateStore } = useStores();
  // In progress, find out how to control dark mode state
  const [isDarkMode, setDarkMode] = useState(mapStateStore.darkMode);

  const mapStateObserver = observe(mapStateStore, (change) => {
    // Change is an object with a couple properties that describe what has changed and how
    setDarkMode(mapStateStore.darkMode);
  });
  const fpiRegionData: FpiRegion[] | null = useFpiLoader().fpiData;
  const [overlayArea, setOverlayArea] = useState<string | null>(null);
  const [selectedArea, setSelectedArea] = useState<string>('mountainempire');

  const onFpiLayerClick = (area: string) => setSelectedArea(area);

  return (
    <ThemeProvider theme={isDarkMode ? darkMode : theme}>
      <Map fpiData={fpiRegionData} onFpiLayerClick={onFpiLayerClick} />
      <Overlay fpiData={fpiRegionData} area={overlayArea} onClose={() => setOverlayArea(null)} />
      <BottomBar area={selectedArea} setOverlayArea={setOverlayArea} />
    </ThemeProvider>
  );
});

export default App;
