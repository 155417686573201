import styled from 'styled-components';
import { ReactComponent as CloseIcon } from 'assets/svg/close_x.svg';
import TooltipOverlay from './components/TooltipOverlay';
import { FpiRegion } from '@sdge-web-app/shared/dist/types';

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Inner = styled.div`
  position: relative;
  width: 100%;
  max-width: 40rem;
  max-height: 90%;
  padding: 3rem;
  background: ${(props) => props.theme.background};
  color: ${(props) => props.theme.foreground};
  border-radius: 1.875rem;
  margin: 1rem;
  display: flex;

  @media (max-width: 400px) {
    padding: 2rem;
  }
`;

const CloseButton = styled.button`
  appearance: none;
  border: none;
  background: none;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  padding: 1rem;

  svg {
    path {
      stroke: ${(props) => props.theme.foreground};
    }
  }

  &:hover {
    opacity: 0.7;
  }
`;

interface Props {
  area: string | null;
  fpiData: FpiRegion[] | null;
  onClose: () => void;
}

const Overlay = ({ area, fpiData, onClose }: Props) => {
  if (area == null) return null;

  return (
    <Background onClick={onClose}>
      <Inner onClick={(e) => e.stopPropagation()}>
        <CloseButton onClick={onClose}>
          <CloseIcon />
        </CloseButton>
        <TooltipOverlay area={area} fpiData={fpiData} />
      </Inner>
    </Background>
  );
};

export default Overlay;
