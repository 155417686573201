import { useState, useEffect } from 'react';
import { WeatherBriefing } from '@sdge-web-app/shared/dist/types';
import { WEATHER_BRIEFING_ENDPOINT } from '../constants';
import { getApiData } from '../utils/axios';
import moment from 'moment-timezone';

export default function useWeatherBriefingLoader(): {
  loading: boolean;
  error: unknown;
  weatherBriefingData: WeatherBriefing | null;
} {
  const [weatherBriefingData, setWeatherBriefingData] = useState<WeatherBriefing | null>(null);
  const [error, setError] = useState<unknown | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!loading) return;
    getApiData(`${WEATHER_BRIEFING_ENDPOINT}?date=${moment().format('YYYYMMDD')}`)
      .then((response) => {
        setWeatherBriefingData(response.data);
      })
      .catch((err) => setError(err))
      .finally(() => setLoading(false));
  }, [loading]);
  return { loading, error, weatherBriefingData };
}
