import { useState, useEffect } from 'react';
import { FpiRegion, FpiSevenDay } from '@sdge-web-app/shared/dist/types';
import { ALL_FPI_DATA_ENDPOINT } from '../constants';
import { FPI_DATA_TYPE } from '@sdge-web-app/shared/dist/constants/fpi';
import { getApiData } from '../utils/axios';

function isFpiRegionData(data: unknown): data is FpiRegion[] {
  return (
    data != null &&
    ((Array.isArray(data as FpiRegion[]) && (data as FpiRegion[]).length > 0) ||
      (Array.isArray(data as FpiSevenDay[]) && (data as FpiSevenDay[]).length > 0))
  );
}

export default function useFpiLoader(): {
  loading: boolean;
  error: unknown;
  fpiData: FpiRegion[] | null;
} {
  const [fpiData, setFpiData] = useState<FpiRegion[] | null>(null);
  const [error, setError] = useState<unknown | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!loading) return;
    getApiData(`${ALL_FPI_DATA_ENDPOINT}?type=${FPI_DATA_TYPE.REGION}`)
      .then((response) => {
        if (isFpiRegionData(response.data)) {
          setFpiData(response.data);
        }
      })
      .catch((err) => setError(err))
      .finally(() => setLoading(false));
  }, [loading]);
  return { loading, error, fpiData };
}
