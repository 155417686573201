import styled, { ThemeContext } from 'styled-components';
import moment from 'moment-timezone';

import { ReactComponent as Logo } from 'assets/svg/logo.svg';
import { useContext } from 'react';

const Wrapper = styled.div`
  width: 15rem;
  height: 100%;
  background-color: ${(props) => props.theme.backgroundBottomNav};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.foreground};
  font-size: 0.75rem;
  text-align: center;

  svg {
    width: 7rem;
    height: auto;
  }

  h1 {
    font-size: 0.875rem;
    text-transform: uppercase;
    margin: 0.5rem 0 0;
  }

  @media (max-width: 767px) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    padding: 1rem 1.5rem;
    flex-direction: row;
    justify-content: flex-start;
    text-align: left;
    gap: 1rem;

    svg {
      width: 8rem;
    }

    h1 {
      margin-top: 0;
    }
  }

  @media (max-width: 360px) {
    padding: 1rem;

    svg {
      width: 6rem;
    }
  }
`;

const Left = () => {
  const themeContext = useContext(ThemeContext);

  return (
    <Wrapper>
      {/* <Logo /> */}
      {themeContext.logo.render({ title: 'SDG&E Logo' })}
      <div>
        <h1>Fire Potential Index</h1>
        <div>{moment().format('dddd MM/DD')}</div>
      </div>
    </Wrapper>
  );
};

export default Left;
