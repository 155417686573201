import { WIDGET_NAMES } from '@sdge-web-app/shared/dist/constants';
import styled from 'styled-components';
import { fpiColor } from '@sdge-web-app/shared/dist/utils';

const Circle = styled.div<{ large?: boolean }>`
  position: relative;
  font-family: Interstate Condensed;
  height: fit-content;
  width: ${({ large }) => (large ? 'clamp(4rem, 20vw, 8rem)' : '4rem')};
  svg {
    width: 100%;
    height: auto;
    margin: 0 auto;

    g path {
      fill: ${(props) => props.theme.backgroundCircle};
    }
  }
`;

const BackgroundColor = styled.div<{
  backgroundColor?: string;
  current?: any;
}>`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: ${({ backgroundColor, current }) => (backgroundColor ? backgroundColor : fpiColor(current))};
`;

const InnerText = styled.div<{ large?: boolean; below?: boolean; degrees?: boolean }>`
  position: absolute;
  top: ${({ below, large }) => (below && large ? '.5rem' : below ? '.25rem' : '-.1rem')};
  left: ${({ degrees }) => (degrees ? '0.1rem' : 0)};
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: ${({ below }) => (below ? 'column' : 'unset')};
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.foreground};
  line-height: ${({ large }) => (large ? 'var(--dial-large-fontsize)' : '1rem')};
  font-size: ${({ large }) => (large ? 'var(--dial-large-fontsize)' : '1rem')};
`;

const TextDecorator = styled.div<{ large?: boolean; below?: boolean }>`
  position: relative;
  bottom: 0.25rem;
  color: #c4c4c4;
  font-size: ${({ large, below }) =>
    below && large ? '.75rem' : large ? 'var(--dial-large-decorator-font-size)' : '.5rem'};
`;

type DialProps = {
  current: string | number | boolean;
  colorHex?: string;
  widgetName?: string;
  innerText?: string;
  large?: boolean;
};

// Solid Dials are used for the FPI, OPI, LOW_TEMP, PAL, RED_FLAG, SAWTI and WIND GUST widgets
// Small size is for use in the side panel, large size is for use in the weather briefing, map pins and infographics

// Will use the current value as the inner text unless innerText variable is specified.
// You you can manually set the color hext of these or use a known widget name to color the dial

export function SolidDial({ current, widgetName, colorHex, innerText, large }: DialProps) {
  const randomClipId = Math.random();

  return (
    <Circle large={large}>
      <svg width="61" height="59" viewBox="0 0 61 59" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_785_15124)">
          <path
            d="M51.3814 27.8149C51.3814 38.7584 42.1179 47.6298 30.6907 47.6298C19.2636 47.6298 10 38.7584 10 27.8149C10 16.8714 19.2636 8 30.6907 8C42.1179 8 51.3814 16.8714 51.3814 27.8149Z"
            fill="white"
          />
        </g>
        <clipPath id={`clip-${randomClipId}`}>
          <path d="M43.4837 38.5306C44.2408 39.1769 45.3815 39.1176 45.9858 38.3267C47.8044 35.9464 48.9956 33.1698 49.4437 30.2341C49.9802 26.7195 49.4282 23.1314 47.856 19.9144C46.2838 16.6974 43.7606 13.9929 40.599 12.1358C37.4373 10.2788 33.7763 9.35097 30.0694 9.46725C26.3624 9.58352 22.7726 10.7388 19.7446 12.79C16.7167 14.8412 14.3837 17.6981 13.0347 21.0067C11.6857 24.3153 11.38 27.9303 12.1555 31.4037C12.804 34.3084 14.1849 37.0074 16.1659 39.2702C16.8196 40.0168 17.9582 40.0016 18.6693 39.3094V39.3094C19.4252 38.5735 19.4047 37.3619 18.7381 36.5443C17.3309 34.818 16.3441 32.8023 15.8624 30.6447C15.242 27.8659 15.4865 24.974 16.5657 22.3271C17.6449 19.6801 19.5113 17.3946 21.9337 15.7537C24.356 14.1128 27.2279 13.1885 30.1934 13.0955C33.159 13.0025 36.0878 13.7448 38.6171 15.2304C41.1464 16.716 43.165 18.8796 44.4228 21.4532C45.6805 24.0269 46.1221 26.8973 45.6929 29.709C45.3602 31.8888 44.5154 33.9589 43.2324 35.7677C42.6205 36.6304 42.6793 37.8439 43.4837 38.5306V38.5306Z" />
        </clipPath>
        <foreignObject x="0" y="0" width="100" height="100" clipPath={`url(#clip-${randomClipId})`}>
          <BackgroundColor backgroundColor={colorHex} current={current} />
        </foreignObject>
        <defs>
          <filter
            id="filter0_d_785_15124"
            x="0.770667"
            y="0.616534"
            width="59.84"
            height="58.0885"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="1.84587" />
            <feGaussianBlur stdDeviation="4.61467" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_785_15124" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_785_15124" result="shape" />
          </filter>
        </defs>
      </svg>
      <InnerText
        data-cy="solid-dial-inner-text"
        large={large}
        below={widgetName === WIDGET_NAMES.WIND_GUST_FORECAST}
        degrees={widgetName === WIDGET_NAMES.LOW_TEMP}>
        {innerText ? innerText : current}
        {widgetName === WIDGET_NAMES.WIND_GUST_FORECAST ? (
          <TextDecorator large={large} below>
            MPH
          </TextDecorator>
        ) : null}
        {widgetName === WIDGET_NAMES.LOW_TEMP ? <TextDecorator large={large}>°</TextDecorator> : null}
      </InnerText>
    </Circle>
  );
}
