import styled from 'styled-components';
import { DISTRICTS } from '@sdge-web-app/shared/dist/constants';

import { ReactComponent as InfoIcon } from 'assets/svg/info.svg';

const Wrapper = styled.div`
  width: 10vw;
  max-width: 12.5rem;
  min-width: 6rem;
  background-color: ${(props) => props.theme.backgroundBottomNav};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.foreground};
  font-size: 0.75rem;
  text-align: center;

  svg {
    cursor: pointer;
  }

  h2 {
    font-size: 0.875rem;
    text-transform: uppercase;
    margin: 1rem 0 0.25rem;
    line-height: 1.1;
  }
`;

const Right = ({ area, setOverlayArea }: { area: string; setOverlayArea: (area: string) => void }) => {
  return (
    <Wrapper>
      <InfoIcon data-cy="open-info-overlay-button" role="button" onClick={() => setOverlayArea(area)} />
      <h2 data-cy="currently-selected-district">{DISTRICTS[area].label}</h2>
      <div>San Diego</div>
    </Wrapper>
  );
};

export default Right;
