import { Layer, Marker, Source } from 'react-map-gl';
import { useMemo } from 'react';
import geoJson from './districts.json';
import { FpiRegion } from '@sdge-web-app/shared/dist/types';
import { fpiColor } from '@sdge-web-app/shared/dist/utils';
import { isFeatureCollection } from './index';
import { WIDGET_NAMES } from '@sdge-web-app/shared/dist/constants';
import { SolidPin } from './SolidPin';
import centerOfMass from '@turf/center-of-mass';
import * as turfHelper from '@turf/helpers';

function determineFillPattern(value: number) {
  if (Number(value) <= 11) {
    return 'dots1';
  } else if (Number(value) >= 12 && Number(value) <= 13) {
    return 'dots2';
  } else if (Number(value) === 14) {
    return 'dots3';
  } else if (Number(value) >= 15) {
    return 'lines';
  } else {
    return null;
  }
}

export default function Fpi({ data }: { data?: FpiRegion[] }) {
  const processedData = useMemo(() => {
    if (data != null && isFeatureCollection(geoJson)) {
      const features = geoJson.features.map((feature) => {
        const region = data.find((region: FpiRegion) => region.region === feature.properties?.id);

        if (region && feature.properties) {
          feature.properties.color = fpiColor(region.fpi ?? 0);
          // @ts-ignore
          feature.properties.fpi = region.fpi;
          // @ts-ignore
          feature.properties.pattern = determineFillPattern(region.fpi ?? 0);

          let featurePolygon = turfHelper.polygon(feature.geometry.coordinates);
          // @ts-ignore
          feature.properties.center = centerOfMass(featurePolygon).geometry.coordinates;
        }

        return { ...feature, properties: { ...feature.properties } };
      });

      return { ...geoJson, features };
    } else {
      return null;
    }
  }, [data]);

  if (!isFeatureCollection(processedData)) return null;

  return (
    <>
      <Source type="geojson" data={processedData}>
        <Layer
          type="fill"
          paint={{
            'fill-color': ['get', 'color'],
            'fill-opacity': 0.7,
            'fill-outline-color': 'black',
          }}
        />
        <Layer
          id="fpi-fill-layer"
          type="fill"
          layout={{ 'fill-sort-key': 2 }}
          paint={{
            'fill-pattern': ['get', 'pattern'],
          }}
        />
      </Source>
      {geoJson.features.map((feature) => (
        <Marker
          key={feature.properties.id}
          longitude={feature.properties.center[0]}
          latitude={feature.properties.center[1]}
          anchor="bottom">
          <SolidPin widgetName={WIDGET_NAMES.FPI} current={feature.properties.fpi} />
        </Marker>
      ))}
    </>
  );
}
