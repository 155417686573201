import React from 'react';
import MapStateStore from './MapStateStore';

/**
 * Root Store Class with
 */
class RootStore {
  mapStateStore: MapStateStore;

  constructor() {
    this.mapStateStore = new MapStateStore();
  }
}

const StoresContext = React.createContext(new RootStore());

// this will be the function available for the app to connect to the stores
export const useStores = () => React.useContext(StoresContext);
