import styled from 'styled-components';
import { DISTRICTS, WIDGET_NAMES } from '@sdge-web-app/shared/dist/constants';
import { fpiLongLevelFromValue } from '@sdge-web-app/shared/dist/utils';
import { FpiRegion } from '@sdge-web-app/shared/dist/types';
import moment from 'moment-timezone';

import useWeatherBriefingLoader from 'hooks/useLoadWeatherBriefingData';
import { SolidPin } from 'components/Map/SolidPin';
import RichTextRenderer from './RichTextRenderer';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  overflow-y: auto;
`;

const Content = styled.div`
  overflow-y: auto;
  width: 100%;
`;

const Title = styled.div`
  font-weight: bold;
  width: 100%;
  padding-bottom: 1rem;
  text-transform: uppercase;
`;

const Pins = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1.5rem;
  margin-top: 1rem;
`;

const Pin = styled.div`
  text-align: center;
  font-size: 0.75rem;
  width: 8rem;

  &:last-child {
    margin-bottom: 1rem;
  }
`;

const Section = styled.div`
  margin: 1rem 0;
`;

const SectionTitle = styled.div`
  font-weight: bold;
  text-transform: uppercase;
`;

export default function TooltipOverlay({ area, fpiData }: { area: string; fpiData: FpiRegion[] | null }) {
  const { weatherBriefingData } = useWeatherBriefingLoader();
  const fireDiscussion = weatherBriefingData?.fire_discussion;
  const district = DISTRICTS[area];
  const todayForArea = fpiData?.find((fpi: FpiRegion) => fpi.region === area);

  return (
    <Wrapper data-cy="tooltip-overlay">
      <Content>
        <Title>Fire Potential Index</Title>
        <div>
          The Fire Potential Index (FPI) is a planning and decision support tool that incorporates weather and fuels
          information to rate the overall fire threat at the district level.
        </div>
        <Pins>
          <Pin>
            <SolidPin widgetName={WIDGET_NAMES.FPI} current={10} innerText={`10`} />
            <div>
              <strong>Normal: &lt; 12</strong>
            </div>
            <div>Wildfires are unlikely.</div>
          </Pin>
          <Pin>
            <SolidPin widgetName={WIDGET_NAMES.FPI} current={12} innerText={`12`} />
            <div>
              <strong>Elevated: 12-14</strong>
            </div>
            <div>Wildfires are possible, should ignitions occur.</div>
          </Pin>
          <Pin>
            <SolidPin widgetName={WIDGET_NAMES.FPI} current={15} innerText={`15`} />
            <div>
              <strong>Extreme: 15-17</strong>
            </div>
            <div>Large, rapidly growing wildfires are possible, should ignitions occur.</div>
          </Pin>
        </Pins>
        {district && (
          <>
            <Section>
              <SectionTitle>Area</SectionTitle>
              <div>{district.label}</div>
            </Section>
            <Section>
              <SectionTitle>Date</SectionTitle>
              <div>{moment().format('dddd MMMM D')}</div>
            </Section>
            {todayForArea?.fpi && (
              <Section>
                <SectionTitle>Threat Level</SectionTitle>
                <div>{fpiLongLevelFromValue(todayForArea.fpi)}</div>
              </Section>
            )}
          </>
        )}
        {fireDiscussion && (
          <Section>
            <SectionTitle>FPI Discussion</SectionTitle>
            <div>
              <RichTextRenderer content={fireDiscussion} />
            </div>
          </Section>
        )}
      </Content>
    </Wrapper>
  );
}
