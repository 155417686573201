import { makeAutoObservable, runInAction } from 'mobx';

class MapStateStore {
  public darkMode: boolean = false;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  toggleDarkMode() {
    runInAction(() => {
      this.darkMode = !this.darkMode;
    });
  }

  setDarkMode(dark: boolean) {
    runInAction(() => {
      this.darkMode = dark;
    });
  }
}

export default MapStateStore;
