import { useContext, useEffect, useRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import Map, { MapRef, NavigationControl } from 'react-map-gl';
import { observer } from 'mobx-react-lite';
import { observe } from 'mobx';
import { FeatureCollection } from 'geojson';
import 'mapbox-gl/dist/mapbox-gl.css';
import { ThemeContext } from 'styled-components';
import { FpiRegion } from '@sdge-web-app/shared/dist/types';

import { MAPBOX_ACCESS_TOKEN } from '../../constants';
import LightModeIcon from '../../assets/svg/light_icon.svg';
import DarkModeIcon from '../../assets/svg/dark_icon.svg';

import dots1 from './png/dots1.png';
import dots2 from './png/dots2.png';
import dots3 from './png/dots3.png';
import lines from './png/lines.png';
import linesCrossed from './png/lines_crossed.png';

import Styles from './Styles';
import Fpi from './Fpi';
import { useStores } from 'store';
import { useMediaQuery } from 'react-responsive';

if (MAPBOX_ACCESS_TOKEN) {
  mapboxgl.accessToken = MAPBOX_ACCESS_TOKEN;
}

export function isFeatureCollection(data: unknown): data is FeatureCollection {
  return typeof data === 'object' && data !== null && 'type' in data && 'features' in data;
}

class DarkModeControl {
  _map: any;
  _container: any;

  darkMode: boolean;
  constructor(dark: boolean) {
    this.darkMode = dark;
  }

  update(dark: boolean) {
    this.darkMode = dark;

    if (this._container == null) return null;

    this._container = document.getElementById('darkmode-toggle-button');
    this._container.className = `darkmode-toggle-button ${this.darkMode ? 'toggle-dark' : ''}`;
    this._container.id = 'darkmode-toggle-button';
    this._container.innerHTML = `<img src='${this.darkMode ? DarkModeIcon : LightModeIcon}' alt='Toggle Dark Mode'/>`;

    return this._container;
  }

  onAdd(map: any) {
    this._map = map;
    this._container = document.createElement('div');
    this._container.className = `darkmode-toggle-button ${this.darkMode ? 'toggle-dark' : ''}`;
    this._container.id = 'darkmode-toggle-button';
    this._container.innerHTML = `<img src='${this.darkMode ? DarkModeIcon : LightModeIcon}' alt='Toggle Dark Mode'/>`;

    return this._container;
  }

  onRemove() {
    this._container = document.getElementById('darkmode-toggle-button');
    this._container.parentNode.removeChild(this._container);
    this._map = undefined;
  }
}

const MapContainer = observer(
  ({ fpiData, onFpiLayerClick }: { fpiData: FpiRegion[] | null; onFpiLayerClick: (area: string) => void }) => {
    // @refresh reset
    // This comment ^ causes this component and children to refresh completely on save.
    const themeContext = useContext(ThemeContext);

    const map = useRef<MapRef | null>(null);
    const [mapLoaded, setMapLoaded] = useState<boolean>(false);
    const isMobile = useMediaQuery({ query: '(max-width: 1140px)' });
    const { mapStateStore } = useStores();

    const [initialBearingSet, setInitialBearingSet] = useState(false);
    const [darkMode, setDarkMode] = useState<boolean>(mapStateStore.darkMode);

    if (!mapboxgl.supported()) return null;

    const darkModeControl = new DarkModeControl(darkMode);

    const mapStateObserver = observe(mapStateStore, (change) => {
      // Change is an object with a couple properties that describe what has changed and how
      setDarkMode((darkModeCurrent) => {
        if (darkModeControl == null) return mapStateStore.darkMode;

        //mapStateStore.toggleDarkMode();
        //darkModeControl.darkMode = !darkModeCurrent;
        darkModeControl.update(mapStateStore.darkMode);

        return mapStateStore.darkMode;
      });
    });

    useEffect(() => {
      if (!mapLoaded) return;

      const darkModeButtonClickHandler = () => {
        setDarkMode((darkModeCurrent) => {
          mapStateStore.toggleDarkMode();
          //darkModeControl.darkMode = !darkModeCurrent;
          darkModeControl.update(mapStateStore.darkMode);

          return mapStateStore.darkMode;
        });
      };

      document.getElementById('darkmode-toggle-button')?.addEventListener('click', darkModeButtonClickHandler);
      return () => {
        document.getElementById('darkmode-toggle-button')?.removeEventListener('click', darkModeButtonClickHandler);
      };
    }, [mapLoaded, mapStateStore]);

    return (
      <Styles isMobile={isMobile}>
        <Map
          ref={map}
          mapStyle={
            mapStateStore.darkMode
              ? 'mapbox://styles/svantenilsonvaltech/cl1nz23b7000015mq2es9ew1t'
              : 'mapbox://styles/svantenilsonvaltech/cldc22lx4001k01p5dh4qaxpc'
          }
          initialViewState={{
            pitch: 50,
            bounds: [
              [-117.7, 32.4],
              [-115.9, 33.7],
            ],
          }}
          maxBounds={[
            [-118.8, 31.7],
            [-115.7, 34.5],
          ]}
          onStyleData={(mapEvent) => {
            // `onStyleData` was the best place to put this.  `onLoad` happens too late for some reason.
            if (!initialBearingSet) {
              mapEvent.target.setBearing(30);
              setInitialBearingSet(true);
            }
          }}
          onLoad={async (mapEvent) => {
            mapEvent.target.rotateTo(0, { duration: 1000 });

            const images = [
              { name: 'dots1', png: dots1 },
              { name: 'dots2', png: dots2 },
              { name: 'dots3', png: dots3 },
              { name: 'lines', png: lines },
              { name: 'lines_crossed', png: linesCrossed },
            ];

            mapEvent.target.on('styleimagemissing', (e) => {
              const id = e.id;
              mapEvent.target.addImage(id, { width: 0, height: 0, data: new Uint8Array() });
              const missingImage = images.find((image) => e.id === image.name);
              if (missingImage) {
                mapEvent.target.loadImage(missingImage.png, (error, img: any) => {
                  if (error) throw error;
                  mapEvent.target.removeImage(id);
                  mapEvent.target.addImage(id, img);
                });
              }
            });

            if (document.getElementById('dark-mode-toggle-button') != null) {
              mapEvent.target.removeControl(darkModeControl);
            }

            mapEvent.target.addControl(darkModeControl);

            await Promise.all(
              images.map((image) =>
                mapEvent.target.loadImage(image.png, (error, img: any) => {
                  if (error) throw error;
                  mapEvent.target.addImage(image.name, img);
                }),
              ),
            ).then(() => {
              setMapLoaded(true);
              return;
            });

            mapEvent.target.on('click', 'fpi-fill-layer', (e) => {
              e.target.getCanvas().style.cursor = 'pointer';
              if (e.features != null) {
                const feature = e.features[0];
                onFpiLayerClick(feature?.properties?.id);
                let center = JSON.parse(feature?.properties?.center);
                mapEvent.target.flyTo({
                  center: { lng: center[0], lat: center[1] },
                  zoom: 9.8,
                });
              }
            });

            mapEvent.target.on('mouseenter', 'fpi-fill-layer', (e) => {
              e.target.getCanvas().style.cursor = 'pointer';
            });

            mapEvent.target.on('mouseleave', 'fpi-fill-layer', (e) => {
              e.target.getCanvas().style.cursor = '';
            });
          }}>
          <NavigationControl showCompass={false} />
          {fpiData != null && <Fpi data={fpiData} />}
        </Map>
      </Styles>
    );
  },
);

export default MapContainer;
