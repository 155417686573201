import { useState, useEffect } from 'react';
import { FPI } from '@sdge-web-app/shared/dist/types';
import { ALL_FPI_DATA_ENDPOINT } from '../constants';
import { getApiData } from 'utils/axios';

export default function useLoadAllFpiData(): {
  loading: boolean;
  error: unknown;
  allFpiData: FPI[] | null;
  fpiTimeOfDay: string;
} {
  const [allFpiData, setAllFpiData] = useState<FPI[] | null>(null);
  const [fpiTimeOfDay, setFpiTimeOfDay] = useState<string>('');
  const [error, setError] = useState<unknown | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!loading) return;
    getApiData(`${ALL_FPI_DATA_ENDPOINT}`)
      .then((response) => {
        if (Array.isArray(response.data.data) && response.data.data.length > 0) {
          setAllFpiData(response.data.data);
          setFpiTimeOfDay(response.data.fpiTimeOfDay);
        }
      })
      .catch((err) => setError(err))
      .finally(() => setLoading(false));
  }, [loading]);
  return { loading, error, allFpiData, fpiTimeOfDay };
}
